import { makeStyles } from "@material-ui/core/styles";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MoveUpOutlinedIcon from "@mui/icons-material/MoveUpOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { Alert, AlertTitle, Box, Tooltip } from "@mui/material";

import {
  DeleteWithConfirmIconButton,
  EditRowButton,
  EditableDatagrid,
  InlineCreateButton,
  RowForm,
} from "@react-admin/ra-editable-datagrid";
import {
  CreateInDialogButton,
  EditInDialogButton,
} from "@react-admin/ra-form-layout";
import {
  LoginRoleTypes,
  getUserRoles,
  hasRole,
  isSuperRole,
} from "components/forms/common/permissions";
import apiConfig from "config/apiconfig.json";
import { format } from "date-fns";
import { pluralize } from "inflection";
import { useEffect, useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Button,
  DateField,
  DeleteWithConfirmButton,
  EmailField,
  FormTab,
  NumberField,
  NumberInput,
  Pagination,
  List as RAList,
  RadioButtonGroupInput,
  ReferenceField,
  SaveButton,
  SaveContextProvider,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  TimeInput,
  Toolbar,
  required,
  useDataProvider,
  useGetManyReference,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdate,
} from "react-admin";

import { Control, useForm, useFormContext, useWatch } from "react-hook-form";
import { StockTransactionHistoryFilters } from "../getForms/ListFilters";
import {
  DependentReferenceInput,
  DisabledField,
  EmptyDataForm,
  EntityIdReferenceInput,
  IBEIdReferenceInput,
  ListActions,
  MyAutoCompleteInput,
  MyAutoCompleteInputWithActions,
  MyCurrentPriceField,
  MyDatagridConfigurable,
  MyNumberInput,
  MyReferenceInput,
  MyReferenceManyField,
  MyStatesReferenceInput,
} from "./FormUtilities";

import {
  ValidateCompareLowHigh,
  ValidateNegative,
  ValidateNumberNonZero,
} from "../forms/common/validation";
import { EndUserFilters } from "./ListFilters";
import {
  TrendFilterChoices,
  TrendIntervalChoices,
} from "components/globals/constants";
const requiredValidate = [required()];
export const useStyles = makeStyles({
  marginTopBottom5px: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  marginTop5px: {
    marginTop: "5px",
  },
  marginBottom5px: {
    marginTop: 0,
    marginBottom: "5px",
  },
  marginLeft5px: {
    marginLeft: "5px!important" as any,
  },
  displayBlock: {
    display: "block",
  },
  isHidden: {
    visibility: "hidden",
    display: "none!important" as any,
  },
  red: {
    color: "#ff0000",
  },
  redPadded: {
    color: "#ff0000",
    padding: "5px",
  },
  fullWidthNoPadding: {
    width: "100%",
  },
  fullWidth: {
    width: "100%",
    padding: "25px 0",
  },
  margin20px: {
    margin: "20px",
  },
  autoWidth: {
    width: "auto",
  },
  marginLeftAuto: {
    marginLeft: "auto!important" as any,
  },
  autoComplete: {
    minWidth: "225px",
    padding: "2px inherit",
    zIndex: 4000,
  },
  width200: {
    width: "200px",
  },
  width75percent: {
    width: "75%",
    textAlign: "right",
    float: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  alignRight: {
    textAlign: "right",
  },
  alignCenter: {
    textAlign: "center",
  },
  numberFormat: {
    fontSize: "1rem!important" as any,
    fontWeight: "bold!important" as any,
  },
  backgroundGray: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
});

export const PostEditToolbar = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext(props);
  let hasRecord = false;
  let resource = props.resource;
  let title = props.title;
  let saveLabel = "Add and Close";
  let allowDelete = true;
  let allowSave = true;
  let allowUpdate = true;
  let isTabbed = false;
  let allowAddAndContinue = true;
  let addButtonLabel = "Add";

  let allowUpdateAndContinue = true;
  let allowUpdateAndContinueLabel = "Save and Continue";
  let performRefresh = true;
  let performRedirect = true;

  let mutationOptions: any;
  let hasMutationOptions = false;

  if (props.allowDelete !== undefined && props.allowDelete !== "") {
    allowDelete = props.allowDelete;
  }

  if (props.mutationOptions !== undefined) {
    mutationOptions = props.mutationOptions;
    hasMutationOptions = true;
  }

  if (props.allowSave !== undefined && props.allowSave !== "") {
    allowSave = props.allowSave;
  }

  if (props.allowUpdate !== undefined && props.allowUpdate !== "") {
    allowUpdate = props.allowUpdate;
  }

  if (props.addButtonLabel !== undefined && props.addButtonLabel !== "") {
    addButtonLabel = props.addButtonLabel;
  }

  if (props.isTabbed !== undefined && props.isTabbed !== "") {
    isTabbed = props.isTabbed;
  }

  if (
    props.allowAddAndContinue !== undefined &&
    props.allowAddAndContinue !== ""
  ) {
    allowAddAndContinue = props.allowAddAndContinue;
  }

  if (
    props.allowUpdateAndContinue !== undefined &&
    props.allowUpdateAndContinue !== ""
  ) {
    allowUpdateAndContinue = props.allowUpdateAndContinue;
  }

  if (props.performRefresh !== undefined && props.performRefresh !== "") {
    performRefresh = props.performRefresh;
  }
  if (props.performRedirect !== undefined && props.performRedirect !== "") {
    performRedirect = props.performRedirect;
  }

  if (record !== undefined) {
    hasRecord = true;
    saveLabel = "Save";

    if (props.updateLabel !== undefined) {
      saveLabel = props.updateLabel;
    }
  }

  const refresh = useRefresh();
  const onSuccess = (data: any) => {
    let successMessage = "Item created successfully";

    if (hasRecord) {
      successMessage = "Item updated successfully";
    }
    notify(successMessage);
    if (performRefresh) {
      refresh();
    }
    if (performRedirect) {
      redirect("/" + resource + "/" + data.id);
    }
  };
  const classes = useStyles();

  let confirmTitle = "Delete " + props.title + " - " + props.name;
  let deleteTitle = "Delete " + props.title;
  let saveTitle = "Save " + props.title;
  let deleteLabel = "Delete " + props.title;

  let content = "Are you sure you wish to delete this item?";

  return (
    <Toolbar {...props}>
      <>
        {!hasRecord && allowSave && !isTabbed && hasMutationOptions && (
          <SaveButton
            label={addButtonLabel}
            mutationOptions={mutationOptions}
            icon={<AddBoxIcon />}
            type="button"
          />
        )}
        {!hasRecord && allowSave && !isTabbed && !hasMutationOptions && (
          <SaveButton
            label={addButtonLabel}
            icon={<AddBoxIcon />}
            type="button"
          />
        )}
        {!hasRecord && isTabbed && allowAddAndContinue && (
          <SaveButton
            sx={{ marginRight: "10px" }}
            label="Add and Continue"
            mutationOptions={{ onSuccess }}
            icon={<AddBoxIcon />}
            type="button"
          />
        )}
        {!hasRecord && isTabbed && (
          <SaveButton
            label={saveLabel}
            title={saveTitle}
            icon={<AddBoxIcon />}
            type="button"
          />
        )}
        {hasRecord && isTabbed && allowUpdateAndContinue && (
          <SaveButton
            sx={{ marginRight: "10px" }}
            label="Save and Continue"
            mutationOptions={{ onSuccess }}
            icon={<AddBoxIcon />}
            type="button"
          />
        )}
        {hasRecord && allowUpdate && (
          <SaveButton label={saveLabel} title={saveTitle} />
        )}
        {hasRecord && allowDelete && (
          <DeleteWithConfirmButton
            title={deleteTitle}
            className={classes.marginLeftAuto}
            confirmTitle={confirmTitle} // 'resources.my_res.delete.title'
            confirmContent={content}
            label={deleteLabel}
          />
        )}
      </>
    </Toolbar>
  );
};

const CustomAction = (props: any) => {
  const record = useRecordContext(props);
  let title = "Delete";
  let displayFieldProp = props.displayField;
  let titleDisplayField = "";

  if (record[displayFieldProp] !== undefined) {
    titleDisplayField = " - " + record[displayFieldProp];
  }

  let label = "Delete " + props.title;
  let confirmTitle = "Delete " + props.title + titleDisplayField;
  let deleteTitle = "Delete " + props.title;
  let saveTitle = "Save " + props.title;
  let deleteLabel = "Delete " + props.title;
  let saveLabel = "Save";
  let content = "Are you sure you wish to delete this item?";
  return (
    <>
      <EditRowButton label="Edit Address" title="Edit Address" />
      <DeleteWithConfirmIconButton
        confirmTitle={confirmTitle} // 'resources.my_res.delete.title'
        confirmContent={content}
        label={label}
        redirect={false}
      />
    </>
  );
};

export const LookUpTypeForm = () => (
  <SimpleForm sx={{ maxWidth: 750 }}>
    <h2>Type</h2>
    <TextInput
      fullWidth
      helperText={false}
      validate={requiredValidate}
      source="name"
    />
  </SimpleForm>
);

export const LocalUserRoleFormEditableGrid = (props: any) => {
  let localUserId = props.localUserId;
  const classes = useStyles();
  return (
    <RowForm>
      <MyReferenceInput source="roleId" reference="roles">
        <MyAutoCompleteInput />
      </MyReferenceInput>

      <TextInput
        source="LocalUserID"
        disabled
        className={classes.isHidden}
        defaultValue={localUserId}
      />
    </RowForm>
  );
};

export const LocalUserTradingPlatformFormEditableGrid = (props: any) => {
  let localUserId = props.localUserId;
  const classes = useStyles();
  return (
    <RowForm>
      <MyReferenceInput source="tradingPlatformId" reference="tradingplatforms">
        <MyAutoCompleteInput />
      </MyReferenceInput>

      <TextInput source="AccessKey" />
      <TextInput source="ClientSecret" />
      <TextInput
        source="LocalUserID"
        className={classes.isHidden}
        defaultValue={localUserId}
        value={localUserId}
      />
    </RowForm>
  );
};

export const StockTransactionsEditableGrid = (props: any) => {
  let stockWatchId = props.stockWatchId;
  const classes = useStyles();
  let today = new Date();
  let todayString = format(today, "MM/dd/Y");
  //queryOptions={{ refetchInterval: 5000 }}

  const postRowSx = (record: any, index: BigInteger) => ({
    backgroundColor: record.HadError ? "red" : "white",
  });

  return (
    <RAList
      disableSyncWithLocation
      empty={<EmptyDataForm title="Transaction History" />}
      resource="stocktransactions"
      title="Transaction History"
      actions={
        <ListActions showFilter={true} showCreate={false} showExport={true} />
      }
      filter={{
        Stockwatchid: stockWatchId,
      }}
      filters={StockTransactionHistoryFilters(todayString)}
      sort={{ field: "CreatedAt", order: "DESC" }}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
    >
      <MyDatagridConfigurable bulkActionButtons={false} rowSx={postRowSx}>
        <TextField source="Action" />
        <MyCurrentPriceField source="CurrentStrikePrice" label="Strike Price" />
        <MyCurrentPriceField source="LastPrice" label="Last Price" />
        <MyCurrentPriceField
          source="AverageExecutionPrice"
          label="Trade Price"
        />
        <MyCurrentPriceField
          source="NetValueFromStocksAfterTrade"
          label="Accumulated Loss"
        />
        <NumberField source="CurrentPosition" label="Current Position" />
        <NumberField source="Quantity" label="Quantity" />
        <MyCurrentPriceField source="GainLossFromTrade" label="Gain/Loss" />
        <TextField source="Reason" />
        <BooleanField
          source="PaperTradeOnly"
          label="Paper Trade"
          FalseIcon={null}
        />
        <BooleanField
          source="LastWSReverseTradeAction"
          label="Reverse Trade Action"
          FalseIcon={null}
        />
        <DateField source="CreatedAt" showTime={true} />
      </MyDatagridConfigurable>
    </RAList>
  );
};

export const StockTransactionEditStalledForm = (props: any) => {
  let hasErrors = false;
  let isCreate = props.dialogType === "Create";
  const record = useRecordContext(props);
  let name = "lastweeklystocktransactions";
  let entityType = "lastweeklystocktransactions";
  let resource = pluralize(entityType).toLowerCase();
  let recordName = "";

  const notify = useNotify();
  const redirectTo = useRedirect();
  const refresh = useRefresh();
  if (record !== undefined) {
    //recordName = record.name;
  }
  const classes = useStyles();

  const [update, { data: data1, isLoading: isLoading1, error: error1 }] =
    useUpdate();

  //fix the onclick callback with message?
  const CustomToolbar = (props: any) => {
    const formContext = useFormContext();
    const { getValues } = useFormContext();
    return (
      <Toolbar {...props}>
        <>
          <SaveButton
            alwaysEnable
            sx={{ marginRight: "10px" }}
            label={"Update This Transaction"}
            icon={<AddBoxIcon />}
            type="button"
            id="updatethistransaction"
            onClick={(e: any) => {
              e.preventDefault(); // necessary to prevent default SaveButton submit logic
              const { id, ...data } = getValues();
              update(
                "updatestalledposition",
                { id: id, data: data },
                {
                  onSuccess: (data1) => {
                    //for some reason, after submit, need to force the
                    //button disabled status
                    //refresh();
                    notify("Update Transaction Complete", {
                      type: "success",
                      autoHideDuration: 5000,
                    });
                  },
                  onError: (error1: any) => {
                    notify("Update Transaction Failed", {
                      type: "warning",
                    });
                  },
                }
              );
            }}
          />

          <SaveButton
            alwaysEnable
            variant="contained"
            color="error"
            sx={{ marginRight: "10px" }}
            label={"Remove This Transaction"}
            id="removethistransaction"
            icon={<RemoveOutlinedIcon />}
            type="button"
            onClick={(e: any) => {
              e.preventDefault(); // necessary to prevent default SaveButton submit logic
              const { id, ...data } = getValues();
              update(
                "rollbackstalledposition",
                { id: id, data: data },
                {
                  onSuccess(data, variables, context) {
                    notify("Remove Transaction Complete", {
                      type: "success",
                      autoHideDuration: 5000,
                    });
                  },
                  onError: (error1: any) => {
                    notify("Remove Transaction Failed", {
                      type: "warning",
                    });
                  },
                }
              );
            }}
          />
        </>
      </Toolbar>
    );
  };

  const save = (data: any) => {
    console.log(data);
  };
  const saving = false;
  const mutationMode = "pessimistic";

  const handleKeypress = (event: any) => {
    //it triggers by pressing the enter key
    //could NOT just rely on an OnSubmit action
    //as that was part of parent form, but then would just CLOSE form
    //and not perform save/remain open action
    //so NOW... on enter, capture, and then specifically run the receive/continue action
    //similar to how it would be run if I clicked that button specifically.
    if (event.key === "Enter" || event.keyCode === 13) {
      let element: any = document.getElementById(
        "updatethistransaction"
      ) as HTMLElement;

      element.click();
    }
  };

  return (
    <SaveContextProvider value={{ save, saving, mutationMode }}>
      <TabbedForm
        syncWithLocation={false}
        onKeyPress={(e) => handleKeypress(e)}
        toolbar={<CustomToolbar mode="onBlur" />}
      >
        <FormTab label={"Position Details"}>
          <Box sx={{ width: "100%", maxWidth: "1200px" }}>
            <Box
              sx={{ width: 1, paddingBottom: { xs: 0, md: "10px" } }}
              display={{ xs: "block", md: "flex" }}
            >
              <Box
                sx={{ width: { xs: 1 } }}
                mr={{ xs: 0, md: "0.5em" }}
                mb={{ xs: 0, sm: 0 }}
              >
                <Box
                  sx={{ width: 1, paddingBottom: "10px" }}
                  display={{ xs: "block", sm: "flex" }}
                >
                  <Box
                    sx={{ width: { xs: 1 } }}
                    mr={{ xs: 0, sm: "0.5em" }}
                    mb={{ xs: "0.5em", sm: 0 }}
                  >
                    <TextInput disabled source="Symbol" />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: 1,
                    paddingTop: "15px",
                    borderTop: 1,
                  }}
                  display={{ xs: "block", sm: "flex" }}
                >
                  <Box sx={{ width: { xs: 1 } }} mr={{ xs: 0, sm: "0.5em" }}>
                    <NumberInput source="Quantity" required />
                  </Box>
                </Box>
                <Box
                  sx={{ width: 1, paddingBottom: "10px" }}
                  display={{ xs: "block", sm: "flex" }}
                >
                  <Box
                    sx={{ width: { xs: 1 } }}
                    mr={{ xs: 0, sm: "0.5em" }}
                    mb={{ xs: "0.5em", sm: 0 }}
                  >
                    <NumberInput source="AverageExecutionPrice" required />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: 1,
                    paddingTop: "15px",
                    borderTop: 1,
                  }}
                  display={{ xs: "block", sm: "flex" }}
                >
                  <Box sx={{ width: { xs: 1 } }} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput disabled source="Action" />
                  </Box>
                </Box>
                <Box sx={{ width: 1 }} display={{ xs: "block", sm: "flex" }}>
                  <Box sx={{ width: { xs: 1 } }} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput disabled source="Reason" label="Last Reason" />
                  </Box>
                </Box>
                <Box sx={{ width: 1 }} display={{ xs: "block", sm: "flex" }}>
                  <Box sx={{ width: { xs: 1 } }} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      disabled
                      sx={{ width: "100%" }}
                      source="LastMessage"
                      label="Last Error"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </FormTab>
      </TabbedForm>
    </SaveContextProvider>
  );
};

export const ItemBundleFormEditableGrid = (props: any) => {
  let masterItemId = props.masterItemId;
  const classes = useStyles();
  return (
    <RowForm>
      <MyReferenceInput
        {...(!hasRole(LoginRoleTypes.SUPER)
          ? {
              filter: {
                internalBusinessEntityId: InternalBusinessEntityId,
                q: "notBundled",
                id_neq: masterItemId,
              },
            }
          : { q: "notBundled", id_neq: masterItemId })}
        source="bundledMasterItemId"
        reference="ibemasteritems"
      >
        <MyAutoCompleteInput label="Bundled Item" />
      </MyReferenceInput>

      <TextInput
        source="masterItemId"
        disabled
        className={classes.isHidden}
        defaultValue={`${masterItemId}`}
      />
    </RowForm>
  );
};

export const ItemSubCategoryFormEditableGrid = (props: any) => {
  let parentCategoryId = props.parentCategoryId;
  const classes = useStyles();
  return (
    <RowForm>
      <TextInput source="name" />
      <TextInput
        source="parentCategoryId"
        disabled
        className={classes.isHidden}
        defaultValue={`${parentCategoryId}`}
      />
      <IBEIdReferenceInput />
    </RowForm>
  );
};

export const CustomerAddressFormEditableGrid = (props: any) => {
  let entityId = props.entityId;
  let entityType = props.entityType;
  const classes = useStyles();
  return (
    <RowForm>
      <TextInput
        fullWidth
        helperText={false}
        validate={requiredValidate}
        source="name"
      />
      <TextInput
        helperText={false}
        source="addressLine1"
        validate={requiredValidate}
      />
      <TextInput helperText={false} source="addressLine2" />
      <TextInput source="city" fullWidth validate={requiredValidate} />
      <MyStatesReferenceInput>
        <MyAutoCompleteInput validate={requiredValidate} />
      </MyStatesReferenceInput>
      <TextInput
        source="postalCode"
        helperText={false}
        validate={requiredValidate}
      />
      <TextInput helperText={false} source="coordinates" />
      <TextInput multiline source="notes" />
      <MyReferenceInput source="addressTypeId" reference="addresstypes">
        <MyAutoCompleteInput validate={requiredValidate} />
      </MyReferenceInput>

      <TextInput
        source="entityType"
        disabled
        className={classes.isHidden}
        defaultValue={`${entityType}`}
      />

      <TextInput
        source="entityId"
        disabled
        className={classes.isHidden}
        defaultValue={`${entityId}`}
      />
    </RowForm>
  );
};

export const SalesOrderItemForm = (props: any) => {
  const record = useRecordContext(props);

  let salesOrderId = props.entityId || props.salesOrderId;
  let contractId = 0;
  let hasContract = false;

  if (props.contractId !== undefined && props.contractId !== 0) {
    contractId = props.contractId;
    hasContract = true;
  }

  const classes = useStyles();

  let entityType = "SalesOrderItem";
  let isCreate = props.dialogType === "Create" || props.createForm;
  let allowAddAndContinue = true;
  let resource = pluralize(entityType).toLowerCase();
  let recordName = "";
  if (record !== undefined) {
    recordName = record.name;
  }
  let name = "Sales Order Item";

  const notify = useNotify();
  const refresh = useRefresh();
  const [update, { isLoading, error }] = useUpdate();

  let isRequired: any;
  isRequired = false;
  const [isContractRequired, SetContractRequired] = useState(isRequired);
  const [isFirstLoad, SetIsFirstLoad] = useState(true);

  if (props.allowAddAndContinue !== undefined) {
    allowAddAndContinue = props.allowAddAndContinue;
  }
  if (props.contractId !== undefined && props.contractId !== 0) {
    contractId = props.contractId;
    hasContract = true;
  }

  const UseWatchEffect = (props: any) => {
    const formContext = useFormContext();

    const record = useRecordContext(props);

    if (isFirstLoad) {
      if (hasContract) {
        SetContractRequired(requiredValidate);
      }
    }

    SetIsFirstLoad(false);

    return <></>;
  };

  return (
    <TabbedForm
      toolbar={
        <PostEditToolbar
          isTabbed={true}
          allowUpdateAndContinue={false}
          allowAddAndContinue={allowAddAndContinue}
          title={name}
          name={recordName}
          resource={resource}
        />
      }
    >
      <FormTab label="Sales Order Item">
        <p>
          <b>NOTE:</b> Master items will NOT be available if contract is
          selected on sales order.
        </p>
        <UseWatchEffect />
        <Box sx={{ width: "100%", maxWidth: "900px" }}>
          <Box
            sx={{ width: 1, paddingBottom: "10px" }}
            display={{ xs: "block", sm: "flex" }}
          >
            {contractId === 0 && (
              <Box
                sx={{ width: { xs: 1, sm: 1 / 2 } }}
                mr={{ xs: 0, sm: "0.5em" }}
                mb={{ xs: "0.5em", sm: 0 }}
              >
                <MyReferenceInput
                  {...(!hasRole(LoginRoleTypes.SUPER)
                    ? {
                        filter: {
                          internalBusinessEntityId: InternalBusinessEntityId,
                        },
                      }
                    : {})}
                  source="masterItemId"
                  reference="ibemasteritems"
                  fullWidth
                >
                  <MyAutoCompleteInputWithActions
                    fullWidth
                    source="name"
                    autofill={true}
                    validate={false}
                    autofillReferenceField={"ibemasteritems"}
                    autofillRelatedFields={[
                      "name",
                      "description",
                      "rate",
                      "unitId",
                    ]}
                    autofillClearField={"contractItemId"}
                  />
                </MyReferenceInput>
              </Box>
            )}
            <Box sx={{ width: { xs: 1, sm: 1 / 2 } }} mr={{ xs: 0 }}>
              <MyReferenceInput
                fullWidth
                {...(!hasRole(LoginRoleTypes.SUPER)
                  ? {
                      filter: {
                        internalBusinessEntityId: InternalBusinessEntityId,
                        contractId: contractId,
                      },
                    }
                  : {})}
                source="contractItemId"
                reference="contractitems"
              >
                <MyAutoCompleteInputWithActions
                  fullWidth
                  autofill={true}
                  source="name"
                  validate={isContractRequired}
                  autofillReferenceField={"contractitems"}
                  autofillRelatedFields={[
                    "name",
                    "description",
                    "rate",
                    "unitId",
                  ]}
                  autofillClearField={"masterItemId"}
                />
              </MyReferenceInput>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ width: 1, paddingBottom: "10px" }}
          display={{ xs: "block", sm: "flex" }}
        >
          <Box
            sx={{ width: { xs: 1, sm: 1 / 2 } }}
            mr={{ xs: 0, sm: "0.5em" }}
            mb={{ xs: "0.5em", sm: 0 }}
          >
            <TextInput
              fullWidth
              helperText={false}
              validate={requiredValidate}
              source="name"
            />
          </Box>

          <Box
            sx={{ width: { xs: 1, sm: 1 / 2 } }}
            mr={{ xs: 0, sm: "0.5em" }}
            mb={{ xs: "0.5em", sm: 0 }}
          >
            <TextInput
              multiline
              fullWidth
              source="description"
              helperText={false}
            />
          </Box>
        </Box>

        <Box
          sx={{ width: 1, paddingBottom: "10px" }}
          display={{ xs: "block", sm: "flex" }}
        >
          <Box
            sx={{ width: { xs: 1, sm: 1 / 2 } }}
            mr={{ xs: 0, sm: "0.5em" }}
            mb={{ xs: "0.5em", sm: 0 }}
          >
            <MyNumberInput
              step={1}
              source="quantity"
              validate={requiredValidate}
              min={0}
            />
          </Box>

          <Box
            sx={{ width: { xs: 1, sm: 1 / 2 } }}
            mr={{ xs: 0, sm: "0.5em" }}
            mb={{ xs: "0.5em", sm: 0 }}
          >
            <MyNumberInput step={1} source="rate" disabled={hasContract} />
          </Box>
        </Box>

        <Box
          sx={{ width: 1, paddingBottom: "10px" }}
          display={{ xs: "block", sm: "flex" }}
        >
          <Box
            sx={{ width: { xs: 1, sm: 1 / 2 } }}
            mr={{ xs: 0, sm: "0.5em" }}
            mb={{ xs: "0.5em", sm: 0 }}
          >
            <MyReferenceInput label="Unit" source="unitId" reference="units">
              <MyAutoCompleteInput disabled={hasContract} validate={false} />
            </MyReferenceInput>
          </Box>

          <Box
            sx={{ width: { xs: 1, sm: 1 / 2 } }}
            mr={{ xs: 0, sm: "0.5em" }}
            mb={{ xs: "0.5em", sm: 0 }}
          >
            <MyNumberInput disabled step={1} source="amount" />
          </Box>
        </Box>

        <TextInput
          source="salesOrderId"
          disabled
          className={classes.isHidden}
          defaultValue={`${salesOrderId}`}
        />
      </FormTab>
    </TabbedForm>
  );
};

export const SalesOrderItemsGrid = (props: any) => {
  // Component to impliment an attachment tab on any simpleform
  const DatagridActionsColumn = ({ label, children }: any) => <>{children}</>;

  // Get the record of the list item
  const record = useRecordContext();
  const classes = useStyles();
  let isAdmin = false;
  if (
    hasRole(LoginRoleTypes.SUPER) ||
    hasRole(LoginRoleTypes.BUSINESS_ADMINISTRATOR)
  ) {
    isAdmin = true;
  }

  let contractId = 0;
  if (props.contractId !== undefined) {
    contractId = props.contractId;
  }

  let showBulkActionButtons = false;
  if (isAdmin) {
    showBulkActionButtons = true;
  }

  // Button on the side of the form to open the edit dialog
  const editButton = (
    <EditInDialogButton
      itemRef="salesorderitems"
      fullWidth
      maxWidth="md"
      sx={{ ".MuiPaper-root": { border: "0px" } }}
      title=" "
    >
      <SalesOrderItemForm
        createForm={false}
        salesOrderId={`${record.id}`}
        contractId={contractId}
      />
    </EditInDialogButton>
  );

  const createButton = (
    <CreateInDialogButton
      label="Sales Order Item"
      title=" "
      fullWidth
      maxWidth="md"
    >
      <SalesOrderItemForm
        salesOrderId={`${record.id}`}
        contractId={contractId}
        createForm={true}
        isCreate={true}
        allowAddAndContinue={false}
      />
    </CreateInDialogButton>
  );

  return (
    <MyReferenceManyField
      label="Sales Order Items"
      reference="salesorderitems"
      target="salesOrderId"
      fullWidth
    >
      {createButton}
      <MyDatagridConfigurable
        bulkActionButtons={showBulkActionButtons}
        className={classes.fullWidth}
      >
        <DatagridActionsColumn>{editButton}</DatagridActionsColumn>

        {(contractId === 0 || contractId === undefined) && (
          <ReferenceField
            label="Master Items"
            source="masterItemId"
            reference="ibemasteritems"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <ReferenceField
          label="Contract Items"
          source="contractItemId"
          reference="contractitems"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="quantity" />
        {isAdmin && <TextField source="rate" />}
        <ReferenceField label="Unit" source="unitId" reference="units">
          <TextField source="name" />
        </ReferenceField>
        {isAdmin && <TextField source="amount" />}
      </MyDatagridConfigurable>
    </MyReferenceManyField>
  );
};

interface FormInputs {
  name: string;
  lastName: string;
}

function FirstNameWatched({ control }: { control: Control<FormInputs> }) {
  const name = useWatch({
    control,
    name: "name", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "default", // default value before the render
  });

  return <p>Watch: {name}</p>; // only re-render at the custom hook level, when firstName changes
}

const DescriptionInput = (props: any) => {
  const name = useWatch({ name: "name" });

  const { setValue } = useFormContext();
  setValue("description", name);
  return (
    <>
      <TextInput
        fullWidth
        helperText={false}
        validate={requiredValidate}
        {...props}
        value={name}
      />

      <p>Watch: {name}</p>
    </>
  );
};

export const FindEndUser = (props: any) => {
  return (
    <RAList
      disableSyncWithLocation
      emptyWhileLoading
      resource="endusers"
      empty={<EmptyDataForm title="End User" />}
      hasCreate={true}
      title="End Users"
      actions={
        <ListActions showFilter={true} showCreate={false} showExport={false} />
      }
      filters={EndUserFilters(InternalBusinessEntityId, isSuperRole())}
      perPage={25}
      filter={{
        internalBusinessEntityId: InternalBusinessEntityId,
        q: "children",
      }}
    >
      <MyDatagridConfigurable rowClick="edit" omit={["disabled"]}>
        <TextField source="name" />
        <TextField source="primaryPhone" />
        <EmailField source="primaryEmail" />
        <ReferenceField
          link={false}
          label="Customer Type"
          source="customerTypeId"
          reference="customertypes"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          link={false}
          label="Customer"
          source="parentCustomerId"
          reference="customers"
        >
          <TextField source="name" />
        </ReferenceField>

        <DisabledField
          source="disabled"
          fieldType="BooleanField"
          label="Disabled?"
        />
      </MyDatagridConfigurable>
    </RAList>
  );
};

const AttachmentsFormTab = (props: any) => {
  const record = useRecordContext();

  const { isLoading, total } = useGetManyReference(
    "storedobjects",
    {
      target: "entityId",
      id: record.id,
      pagination: { page: 1, perPage: 25 },
      sort: { field: "id", order: "DESC" },
      filter: { entityType: props.entityType, includeContent: false },
    },
    {
      enabled: !!record,
    }
  );
  let label = "Attachments";
  if (!isLoading) {
    label += ` (${total})`;
  }
  return <FormTab label={label} {...props} />;
};

const CopySalesEstimateToSalesOrder = (record: any) => {
  //https://marmelab.com/react-admin/doc/2.9/Actions.html#using-a-custom-action-creator
  //https://marmelab.com/react-admin/Actions.html#calling-custom-methods
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const salesOrderId = record.salesOrderId;

  const [update, { isLoading, error, isSuccess, data }] = useUpdate(
    "copyestimatetosalesorder",
    { id: salesOrderId, data: { id: salesOrderId }, previousData: record }
  );

  const handleClick = () => {
    update();
  };

  if (error) {
    let i = 0;
    // @ts-ignore
    return <p>{error.message}</p>;
  }

  if (isSuccess) {
    notify("New sales order created successfully...redirecting");
    redirect("/salesorders/" + data.id);
  }

  return (
    <Button
      variant="outlined"
      color="primary"
      label="Copy to New Sales Order"
      onClick={handleClick}
      disabled={isLoading}
      size="medium"
      startIcon={<MoveUpOutlinedIcon sx={{ color: "primary" }} />}
    />
  );
};

const showThisField = (
  watchFieldValue: string,
  watchValue: string,
  watchComparison: string
): boolean => {
  let showField = false;
  if (watchComparison === "===") {
    if (watchFieldValue === watchValue) {
      showField = true;
    } else {
      showField = false;
    }
  } else if (watchComparison === ">=") {
    if (watchFieldValue >= watchValue) {
      showField = true;
    } else {
      showField = false;
    }
  } else if (watchComparison === "<=") {
    if (watchFieldValue <= watchValue) {
      showField = true;
    } else {
      showField = false;
    }
  } else if (watchComparison === "!==") {
    if (watchValue == "undefined") {
      if (watchFieldValue !== undefined) {
        showField = true;
      } else {
        showField = false;
      }
    } else {
      if (watchFieldValue !== watchValue) {
        showField = true;
      } else {
        showField = false;
      }
    }
  }

  return showField;
};

export const ShowHideField = (props: any) => {
  let showField = false;
  let watchField = "";
  let watchValue = "";
  let watchComparison = "===";
  let secondWatchField = "";
  let secondWatchValue = "";
  let secondWatchComparison = "===";
  let useSecondWatch = false;
  let defaultValue: any;

  if (props.defaultValue !== undefined && props.defaultValue !== "") {
    defaultValue = props.defaultValue;
  }

  if (props.watchField !== undefined && props.watchField !== "") {
    watchField = props.watchField;
  }

  if (props.watchValue !== undefined && props.watchValue !== "") {
    watchValue = props.watchValue;
  }

  if (props.watchComparison !== undefined && props.watchComparison !== "") {
    watchComparison = props.watchComparison;
  }

  let watchFieldValue = useWatch({
    name: watchField,
    defaultValue: defaultValue,
  });

  //ONLY set watchFieldValue if is NOT yet set, but a default value is passed in
  if (defaultValue !== undefined && watchFieldValue === undefined) {
    watchFieldValue = defaultValue;
  }

  if (props.secondWatchField !== undefined && props.secondWatchField !== "") {
    secondWatchField = props.secondWatchField;
    useSecondWatch = true;
  }

  if (props.secondWatchValue !== undefined && props.secondWatchValue !== "") {
    secondWatchValue = props.secondWatchValue;
  }

  if (
    props.secondWatchComparison !== undefined &&
    props.secondWatchComparison !== ""
  ) {
    secondWatchComparison = props.secondWatchComparison;
  }

  const secondWatchFieldValue = useWatch({
    name: secondWatchField,
    defaultValue: undefined,
  });

  showField = showThisField(watchFieldValue, watchValue, watchComparison);

  if (useSecondWatch) {
    showField =
      showField &&
      showThisField(secondWatchFieldValue, watchValue, watchComparison);
  }

  if (!showField) {
    return <></>;
  }

  return <>{props.children}</>;
};

const RolePermissionsFormEditableGrid = (props: any) => {
  let roleTypeId = props.roleTypeId;
  const classes = useStyles();
  return (
    <RowForm>
      <MyReferenceInput
        label="Models"
        source="systemModelId"
        reference="systemmodels"
        fullWidth
      >
        <MyAutoCompleteInput
          fullWidth
          source="name"
          autofill={true}
          validate={requiredValidate}
        />
      </MyReferenceInput>
      <MyReferenceInput
        label="Permissions"
        source="permissionTypeId"
        reference="permissiontypes"
      >
        <MyAutoCompleteInput
          fullWidth
          validate={requiredValidate}
          source="name"
        />
      </MyReferenceInput>

      <TextInput
        source="roleTypeId"
        disabled
        className={classes.isHidden}
        defaultValue={`${roleTypeId}`}
      />
    </RowForm>
  );
};

export const TradingPlatformForm = (props: any) => {
  let isCreate = props.dialogType === "Create";
  const record = useRecordContext(props);
  let name = "Trading Platform";
  let entityType = "TradingPlatform";
  let resource = pluralize(entityType).toLowerCase();
  let roleTypeId = 0;
  let recordName = "";
  if (record !== undefined) {
    recordName = record.name;
    roleTypeId = Number(record.id);
  }
  const classes = useStyles();

  return (
    <TabbedForm
      toolbar={
        <PostEditToolbar
          isTabbed={true}
          title={name}
          name={recordName}
          resource={resource}
        />
      }
    >
      <FormTab label={name}>
        <Box sx={{ width: "100%", maxWidth: "1200px" }}>
          <Box
            sx={{ width: 1, paddingBottom: { xs: 0, md: "10px" } }}
            display={{ xs: "block", md: "flex" }}
          >
            <Box
              sx={{ width: { xs: 1, md: 1 / 2 } }}
              mr={{ xs: 0, md: "0.5em" }}
              mb={{ xs: 0, sm: 0 }}
            >
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1, md: 3 / 5 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    autoFocus
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    source="name"
                  />
                </Box>
              </Box>
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1, md: 3 / 5 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    autoFocus
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    source="key"
                  />
                </Box>
              </Box>
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1, md: 3 / 5 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    autoFocus
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    label="API Url"
                    source="apiUrl"
                  />
                </Box>
              </Box>
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1, md: 3 / 5 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    autoFocus
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    label="API Audience"
                    source="apiAudience"
                  />
                </Box>
              </Box>
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1, md: 3 / 5 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    autoFocus
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    label="Authorize URL"
                    source="authorizeUrl"
                  />
                </Box>
              </Box>
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1, md: 3 / 5 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    autoFocus
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    label="Authorization Token Url"
                    source="authTokenUrl"
                  />
                </Box>
              </Box>
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1, md: 3 / 5 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    autoFocus
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    label="Redirect URI"
                    source="redirectUri"
                  />
                </Box>
              </Box>
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1, md: 3 / 5 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    autoFocus
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    label="API Scope"
                    source="apiScope"
                  />
                </Box>
              </Box>
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1, md: 3 / 5 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    autoFocus
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    label="Response Type"
                    source="responseType"
                  />
                </Box>
              </Box>
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    multiline
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    source="description"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </FormTab>
    </TabbedForm>
  );
};

export const WeeklyStockWatchForm = (props: any) => {
  let isCreate = props.dialogType === "Create";
  let isFormDisabled = false;
  const record = useRecordContext(props);
  let name = "Stock Watch";
  let entityType = "WeeklyStockWatch";
  let resource = pluralize(entityType).toLowerCase();
  let roleTypeId = 0;
  let recordName = "";
  if (record !== undefined) {
    recordName = record.name;
    roleTypeId = Number(record.id);
    isFormDisabled = record.HasStoppedTrading;
  }
  const classes = useStyles();
  const [isFirstLoad, SetIsFirstLoad] = useState(true);

  const [isStockStalled, setIsDisabled] = useState(isFormDisabled);

  const [symbolDescription, SetSymbolDescription] = useState("");

  const [usePerformTrailingStopLoss, SetUsePerformTrailingStopLoss] =
    useState(false);

  const [useCutoffTiers, SetUseCutoffTiers] = useState(false);
  const [UseTrendFollowMode, SetUseTrendFollowMode] = useState(false);
  const [UseAutoBalance, SetUseAutoBalance] = useState(false);
  const [UseAutoStart, SetUseAutoStart] = useState(false);

  const hasStoppedTrading = (record: any) => {
    let hasStopped = false;

    //const thisRecord = useRecordContext(props);

    if (record !== undefined) {
      hasStopped =
        record.HasStoppedTrading ||
        (record.CurrentLongPosition === null &&
          record.CurrentShortPosition === null);
    }

    return hasStopped;
  };

  const hasTradedWithinRangeAndNotStopLossInUse = (record: any) => {
    let hasStopped = false;

    //const thisRecord = useRecordContext(props);

    if (record !== undefined) {
      hasStopped = record.HasTradedWithinRange && !record.TrailingStopLossInUse;
    }

    return hasStopped;
  };

  const hasTradedWithinRange = (record: any) => {
    let hasTradedWithinRange = false;

    //const thisRecord = useRecordContext(props);

    if (record !== undefined) {
      hasTradedWithinRange = record.HasTradedWithinRange;
    }

    return hasTradedWithinRange;
  };

  const UseWatchEffect = (props: any) => {
    const formContext = useFormContext();

    if (isFirstLoad && record !== undefined) {
      SetUsePerformTrailingStopLoss(record.UseTrailingStopLossFeature);
      SetUseCutoffTiers(record.UseTrailingStopLossCutoffTiers);
      SetUseTrendFollowMode(record.UseTrendFollowMode);
      SetUseAutoStart(record.UseAutoStart);
      SetUseAutoBalance(record.UseAutoBalance);
    }

    SetIsFirstLoad(false);

    useEffect(() => {
      const UseWatchEffect = formContext.watch((value, { name, type }) => {
        if (name === "UseTrailingStopLossFeature") {
          if (value[name] !== undefined) {
            SetUsePerformTrailingStopLoss(value[name]);
          }
          if (!value[name]) {
            SetUseCutoffTiers(false);
            formContext.setValue("UseTrailingStopLossCutoffTiers", false, {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("StopLossHurdlePercent", 0, {
              shouldTouch: true,
              shouldDirty: true,
            });
          }
        }
        if (name === "UseTrailingStopLossCutoffTiers") {
          if (value[name] !== undefined) {
            SetUseCutoffTiers(value[name]);
          }
          if (!value[name]) {
            //is false
            //***            this is NOT working/Saving
            formContext.setValue("StopLossHurdlePercentTier1", 0, {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("StopLossHurdlePercentTier2", 0, {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("StopLossHurdlePercentTier3", 0, {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("StopLossHurdlePercentTier4", 0, {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("CutoffPercentTier1High", 0, {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("CutoffPercentTier2High", 0, {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("CutoffPercentTier3High", 0, {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("CutoffPercentTier4High", 0, {
              shouldTouch: true,
              shouldDirty: true,
            });

            formContext.setValue("SoftCloseOnStopLossHurdle", false, {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("SoftCloseOnTier1", false, {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("SoftCloseOnTier2", false, {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("SoftCloseOnTier3", false, {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("SoftCloseOnTier4", false, {
              shouldTouch: true,
              shouldDirty: true,
            });
          }
        }
        if (name === "UseTrendFollowMode") {
          if (value[name] !== undefined) {
            SetUseTrendFollowMode(value[name]);
          }
          if (!value[name]) {
            //is false
            //***            this is NOT working/Saving
            SetUseAutoStart(false);
            formContext.setValue("UseAutoStart", false, {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("TrendFilter", "", {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("TrendInterval", "", {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("TrendCount", 0, {
              shouldTouch: true,
              shouldDirty: true,
            });
          }
        }
        if (name === "UseAutoStart") {
          if (value[name] !== undefined) {
            SetUseAutoStart(value[name]);
          }
          /*
          formContext.setValue("LowStrikeZone", 0, {
            shouldTouch: true,
            shouldDirty: true,
          });
          formContext.setValue("HighStrikeZone", 0, {
            shouldTouch: true,
            shouldDirty: true,
          });
          */
        }
        if (name === "UseAutoBalance") {
          if (value[name] !== undefined) {
            SetUseAutoBalance(value[name]);
          }
          formContext.setValue("JettisonPercent", 0, {
            shouldTouch: true,
            shouldDirty: true,
          });
        }
      });
      return () => UseWatchEffect.unsubscribe();
    });

    return <></>;
  };

  const isSymbolValid = async (value: string) => {
    let newData: any;
    let localUserID = PersonId;

    const headers = { Authorization: apiConfig.api_value };
    const response = await fetch(
      apiConfig.api_url +
        "/symbollookup?symbol=" +
        value +
        "&localUserID=" +
        localUserID,
      { headers }
    );
    newData = await response.json();
    if (newData == null) {
      SetSymbolDescription("");
      return [false, ""];
    }
    if (response.status != 200) {
      SetSymbolDescription("");
      return [false, newData];
    }

    SetSymbolDescription(newData);
    return [true, ""];
  };

  const ValidateSymbol = async (value: string) => {
    if (isCreate) {
      const [symbolValid, symbolDescription] = await isSymbolValid(value);
      if (!symbolValid) {
        return symbolDescription;
      }
    }
    return undefined;
  };

  const HandleBooleanChange = (isChecked: any) => {
    //const formContext = useFormContext();
    if (!isChecked) {
      let element: any = document.getElementById(
        "CutoffPercentTier1High"
      ) as HTMLElement;
      element.value = "";
      element = document.getElementById(
        "StopLossHurdlePercentTier1"
      ) as HTMLElement;
      element.value = 0;
      //formContext.setValue("CutoffPercentTier1High", "");
    }

    return null;
  };

  return (
    <TabbedForm
      reValidateMode="onBlur"
      toolbar={
        <PostEditToolbar
          isTabbed={true}
          title={name}
          name={recordName}
          resource={resource}
        />
      }
    >
      <FormTab disabled={isStockStalled} label={name}>
        {isStockStalled && (
          <Alert severity="warning" className={classes.margin20px}>
            <AlertTitle>Warning</AlertTitle>
            Position is Stalled and this form is diabled. To Unstall this form
            please use the Edit Stalled Position Feature.
          </Alert>
        )}
        <UseWatchEffect />
        <Box sx={{ width: "100%", maxWidth: "1200px" }}>
          <Box
            sx={{ width: 1, paddingBottom: { xs: 0 } }}
            display={{ xs: "block", md: "flex" }}
          >
            <Box
              sx={{ width: { xs: 1 } }}
              mr={{ xs: 0, md: "0.5em" }}
              mb={{ xs: 0, sm: 0 }}
            >
              <Box
                sx={{ width: 1, paddingBottom: "5px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1, md: 2 / 5 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    autoFocus
                    fullWidth
                    helperText={false}
                    validate={[required(), ValidateSymbol]}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    source="Symbol"
                    disabled={!isCreate}
                  />
                  <span>{symbolDescription}</span>
                </Box>

                <Box
                  sx={{ width: { xs: 1, md: 3 / 5 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <BooleanInput
                    source="HasStoppedTrading"
                    label="Force Stall"
                    fullWidth={false}
                    defaultChecked={false}
                    defaultValue={false}
                    disabled={hasStoppedTrading(record)}
                  />
                  <span className={classes.red}>
                    NOTE: Cannot stall position not actively trading{" "}
                  </span>
                </Box>
              </Box>

              <Box
                sx={{
                  width: 1,
                  paddingBottom: "5px",
                  paddingTop: "15px",
                  borderTop: 1,
                }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box sx={{ width: { xs: 1, sm: 1 / 3 } }}>
                  <NumberInput
                    helperText={false}
                    validate={[required(), ValidateNumberNonZero]}
                    source="BaseQuantity"
                  />
                </Box>
                <Box sx={{ width: { xs: 1, sm: 2 / 3 } }}>
                  <Box
                    sx={{ width: { xs: 1 } }}
                    display={{ xs: "block", sm: "flex" }}
                  >
                    <Box sx={{ width: { xs: 1, sm: 1 / 3 } }}>
                      <BooleanInput
                        source="PaperTradeOnly"
                        fullWidth={false}
                        defaultChecked={true}
                        defaultValue={true}
                        disabled={isStockStalled}
                      />
                    </Box>
                    <Box sx={{ width: { xs: 1, sm: 1 / 3 } }}>
                      <BooleanInput
                        source="ReverseTradeAction"
                        fullWidth={false}
                        disabled={isStockStalled}
                      />
                    </Box>
                    <Box sx={{ width: { xs: 1, sm: 1 / 3 } }}>
                      <BooleanInput
                        source="UseScalpingFeature"
                        fullWidth={false}
                        disabled={isStockStalled}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  width: 1,
                  paddingBottom: "5px",
                  paddingTop: "15px",
                  borderTop: 1,
                }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                  <TimeInput
                    source="TradingStartTime"
                    validate={requiredValidate}
                  />
                </Box>
                <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                  <NumberInput
                    source="StrikePriceOffsetPercent"
                    step={0.01}
                    validate={[required(), ValidateNumberNonZero]}
                  />
                </Box>
                <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                  <NumberInput
                    source="TrailingStopLossPercent"
                    step={0.01}
                    validate={[required(), ValidateNumberNonZero]}
                  />
                </Box>
                <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                  <NumberInput
                    source="BadGapPercent"
                    step={0.01}
                    validate={[required(), ValidateNumberNonZero]}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  width: 1,
                  paddingBottom: "0px",
                  paddingTop: "15px",
                  borderTop: 1,
                }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box sx={{ width: { xs: 1, sm: 1 / 2 } }}>
                  <Box
                    sx={{ width: { xs: 1 } }}
                    display={{ xs: "block", sm: "flex" }}
                  >
                    <BooleanInput
                      source="UseAutoBalance"
                      label="Use Auto Balance"
                      value={UseAutoBalance}
                    />
                  </Box>
                </Box>
              </Box>
              {UseAutoBalance && (
                <Box
                  sx={{
                    width: 1,
                    paddingBottom: "5px",
                  }}
                  display={{ xs: "block", sm: "flex" }}
                >
                  <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                    <span>
                      <NumberInput
                        source="JettisonPercent"
                        step={0.01}
                        disabled={isStockStalled}
                        validate={[required(), ValidateNumberNonZero]}
                      />
                    </span>
                  </Box>
                </Box>
              )}

              <Box
                sx={{
                  width: 1,
                  paddingBottom: "0px",
                  paddingTop: "15px",
                  borderTop: 1,
                }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box sx={{ width: { xs: 1, sm: 1 / 2 } }}>
                  <Box
                    sx={{ width: { xs: 1 } }}
                    display={{ xs: "block", sm: "flex" }}
                  >
                    <BooleanInput
                      source="UseTrendFollowMode"
                      label="Use Trend Follow Mode"
                      value={UseTrendFollowMode}
                    />
                  </Box>
                </Box>
              </Box>
              {UseTrendFollowMode && (
                <Box
                  sx={{
                    width: 1,
                    paddingBottom: "5px",
                  }}
                  display={{ xs: "block", sm: "flex" }}
                >
                  <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                    <Tooltip
                      placement="top-start"
                      title={
                        "Specify to retrieve all data points, or data points during market hours. One of: all, open."
                      }
                    >
                      <span>
                        <SelectInput
                          sx={{
                            marginTop: "0px",
                          }}
                          source="TrendFilter"
                          choices={TrendFilterChoices}
                          validate={[required()]}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                  <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                    <Tooltip
                      title="Interval of time per timesale. One of: min, 5min, 15min "
                      placement="top-start"
                    >
                      <span>
                        <SelectInput
                          sx={{
                            marginTop: "0px",
                          }}
                          source="TrendInterval"
                          choices={TrendIntervalChoices}
                          validate={[required()]}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                  <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                    <Tooltip title="Count Value" placement="top-start">
                      <span>
                        <NumberInput
                          source="TrendCount"
                          step={1}
                          validate={[required(), ValidateNumberNonZero]}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                  <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                    <BooleanInput
                      source="UsePeaksAndValleys"
                      fullWidth={false}
                      disabled={isStockStalled}
                    />
                  </Box>
                </Box>
              )}

              <Box
                sx={{
                  width: 1,
                  paddingBottom: "0px",
                  paddingTop: "15px",
                  borderTop: 1,
                }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{
                    width: 1,
                    paddingBottom: "0px",
                  }}
                  display={{ xs: "block", sm: "flex" }}
                >
                  <Box
                    sx={{ width: { xs: 1 } }}
                    display={{ xs: "block", sm: "flex" }}
                  >
                    <BooleanInput
                      source="UseAutoStart"
                      label="Use Auto Start (ONLY available if Using Trend Follow Mode)"
                      value={UseAutoStart}
                      checked={UseAutoStart}
                      disabled={!UseTrendFollowMode}
                    />
                  </Box>
                </Box>
              </Box>
              {!UseAutoStart && (
                <Box
                  sx={{
                    width: 1,
                    paddingBottom: "5px",
                  }}
                  display={{ xs: "block", sm: "flex" }}
                >
                  <Box sx={{ width: { xs: 1, sm: 1 / 2 } }}>
                    <Box
                      sx={{ width: { xs: 1 } }}
                      display={{ xs: "block", sm: "flex" }}
                    >
                      <span>
                        One of the stike zones (low/high) will be used to set
                        the Actual Strike Zone
                      </span>
                    </Box>
                  </Box>
                  <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                    <Tooltip
                      placement="top-start"
                      title={
                        hasTradedWithinRangeAndNotStopLossInUse(record)
                          ? "CAN NOT be modified when Trading is Active"
                          : ""
                      }
                    >
                      <span>
                        <NumberInput
                          source="LowStrikeZone"
                          step={0.01}
                          disabled={
                            hasTradedWithinRange(record) || isStockStalled
                          }
                          validate={[required(), ValidateNumberNonZero]}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                  <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                    <Tooltip
                      placement="top-start"
                      title={
                        hasTradedWithinRangeAndNotStopLossInUse(record)
                          ? "CAN NOT be modified when Trading is Active"
                          : ""
                      }
                    >
                      <span>
                        <NumberInput
                          source="HighStrikeZone"
                          step={0.01}
                          disabled={
                            hasTradedWithinRange(record) || isStockStalled
                          }
                          validate={[required(), ValidateNumberNonZero]}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  width: 1,
                  paddingBottom: "5px",
                  paddingTop: "0px",
                  borderTop: 0,
                }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box sx={{ width: { xs: 1, sm: 1 / 2 } }}></Box>
                <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                  <Tooltip
                    placement="top-start"
                    title={
                      hasTradedWithinRangeAndNotStopLossInUse(record)
                        ? ""
                        : "CAN ONLY be modified when Trading is Active AND Trailing Stop Loss feature IS NOT in use"
                    }
                  >
                    <span>
                      <NumberInput
                        source="StrikePrice"
                        step={0.01}
                        disabled={
                          UseAutoStart ||
                          !hasTradedWithinRangeAndNotStopLossInUse(record)
                        }
                      />
                    </span>
                  </Tooltip>
                </Box>
                <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                  <Tooltip
                    title="Saved after initial trading begins"
                    placement="top-start"
                  >
                    <span>
                      <NumberInput
                        source="OriginalStrikePrice"
                        step={0.01}
                        disabled={true}
                      />
                    </span>
                  </Tooltip>
                </Box>
              </Box>
              <Box
                sx={{
                  width: 1,
                  paddingBottom: "5px",
                  paddingTop: "15px",
                  borderTop: 1,
                }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box sx={{ width: { xs: 1, sm: 1 / 3 } }}>
                  <NumberInput
                    source="RecoveryLimitPercent"
                    step={0.01}
                    validate={[required(), ValidateNumberNonZero]}
                  />
                </Box>
                <Box sx={{ width: { xs: 1, sm: 1 / 3 } }}>
                  <NumberInput
                    source="PositionDecreaseIntervalPercent"
                    step={0.01}
                    validate={[required(), ValidateNumberNonZero]}
                  />
                </Box>
                <Box sx={{ width: { xs: 1, sm: 1 / 3 } }}>
                  <NumberInput
                    source="ObjectivePercent"
                    step={0.01}
                    validate={[required(), ValidateNumberNonZero]}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  width: 1,
                  paddingBottom: "5px",
                }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                  <NumberInput
                    source="CutoffPercent"
                    label="Hard Cutoff %"
                    step={0.01}
                    validate={[
                      ValidateCompareLowHigh,
                      ValidateNegative,
                      required(),
                    ]}
                  />

                  <Box sx={{ width: { xs: 1 } }}>
                    <BooleanInput
                      source="UseTrailingStopLossFeature"
                      label="Perform Trailing Stop Loss"
                      disabled={isStockStalled}
                    />
                  </Box>
                </Box>
                {usePerformTrailingStopLoss && useCutoffTiers && (
                  <Box sx={{ width: { xs: 1, sm: 3 / 4 } }}>
                    <Box
                      sx={{ width: { xs: 1 } }}
                      display={{ xs: "block", sm: "flex" }}
                    >
                      <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                        <NumberInput
                          label="Tier 1"
                          source="CutoffPercentTier1High"
                          validate={[required(), ValidateCompareLowHigh]}
                          step={0.01}
                        />
                      </Box>
                      <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                        <NumberInput
                          label="Tier 2"
                          source="CutoffPercentTier2High"
                          validate={[required(), ValidateCompareLowHigh]}
                          step={0.01}
                        />
                      </Box>
                      <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                        <NumberInput
                          label="Tier 3"
                          source="CutoffPercentTier3High"
                          validate={[required(), ValidateCompareLowHigh]}
                          step={0.01}
                        />
                      </Box>
                      <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                        <NumberInput
                          label="Tier 4"
                          source="CutoffPercentTier4High"
                          validate={[required(), ValidateCompareLowHigh]}
                          step={0.01}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  width: 1,
                  paddingBottom: "5px",
                  paddingTop: "15px",
                  borderTop: 1,
                }}
                display={{ xs: "block", sm: "flex" }}
              >
                {usePerformTrailingStopLoss && (
                  <Box sx={{ width: { xs: 1, sm: 1 / 2 } }}>
                    <BooleanInput
                      source="UseTrailingStopLossCutoffTiers"
                      label="Use Cutoff Tiers"
                      value={useCutoffTiers}
                      disabled={isStockStalled}
                    />
                  </Box>
                )}
              </Box>
              {usePerformTrailingStopLoss && (
                <Box
                  sx={{
                    width: 1,
                    paddingBottom: "5px",
                  }}
                  display={{ xs: "block", sm: "flex" }}
                >
                  <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                    <NumberInput
                      source="StopLossHurdlePercent"
                      step={0.01}
                      validate={[required(), ValidateNumberNonZero]}
                    />
                  </Box>
                  {usePerformTrailingStopLoss && useCutoffTiers && (
                    <Box sx={{ width: { xs: 1, sm: 3 / 4 } }}>
                      <Box
                        sx={{ width: { xs: 1 } }}
                        display={{ xs: "block", sm: "flex" }}
                      >
                        <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                          <NumberInput
                            label="Tier 1"
                            source="StopLossHurdlePercentTier1"
                            step={0.01}
                          />
                        </Box>
                        <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                          <NumberInput
                            label="Tier 2"
                            source="StopLossHurdlePercentTier2"
                            step={0.01}
                          />
                        </Box>
                        <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                          <NumberInput
                            label="Tier 3"
                            source="StopLossHurdlePercentTier3"
                            step={0.01}
                          />
                        </Box>
                        <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                          <NumberInput
                            label="Tier 4"
                            source="StopLossHurdlePercentTier4"
                            step={0.01}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              {usePerformTrailingStopLoss && useCutoffTiers && (
                <Box
                  sx={{
                    width: 1,
                    paddingBottom: "5px",
                  }}
                  display={{ xs: "block", sm: "flex" }}
                >
                  <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                    <BooleanInput
                      source="SoftCloseOnStopLossHurdle"
                      label="Perform Soft Close"
                      disabled={isStockStalled}
                    />
                  </Box>
                  <Box sx={{ width: { xs: 1, sm: 3 / 4 } }}>
                    <Box
                      sx={{ width: { xs: 1 } }}
                      display={{ xs: "block", sm: "flex" }}
                    >
                      <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                        <BooleanInput
                          label="On Tier 1"
                          source="SoftCloseOnTier1"
                          disabled={isStockStalled}
                        />
                      </Box>
                      <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                        <BooleanInput
                          label="On Tier 2"
                          source="SoftCloseOnTier2"
                          disabled={isStockStalled}
                        />
                      </Box>
                      <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                        <BooleanInput
                          label="On Tier 3"
                          source="SoftCloseOnTier3"
                          disabled={isStockStalled}
                        />
                      </Box>
                      <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                        <BooleanInput
                          label="On Tier 4"
                          source="SoftCloseOnTier4"
                          disabled={isStockStalled}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              {usePerformTrailingStopLoss && (
                <Box
                  sx={{
                    width: 1,
                    paddingBottom: "5px",
                    paddingTop: "15px",
                    borderTop: 1,
                  }}
                  display={{ xs: "block", sm: "flex" }}
                >
                  <Box sx={{ width: { xs: 1, sm: 1 / 4 } }}>
                    <BooleanInput source="TrailingStopLossInUse" disabled />
                  </Box>
                  <Box sx={{ width: { xs: 1, sm: 3 / 4 } }}>
                    <Box
                      sx={{ width: { xs: 1 } }}
                      display={{ xs: "block", sm: "flex" }}
                    >
                      <Box sx={{ width: { xs: 1, sm: 1 / 3 } }}>
                        <NumberInput
                          source="LastStopLossHurdleValue"
                          disabled
                          step={0.01}
                        />
                      </Box>
                      <Box sx={{ width: { xs: 1, sm: 1 / 3 } }}>
                        <NumberInput
                          source="LastStopLossMaxValue"
                          step={0.01}
                          disabled
                        />
                      </Box>
                      <Box sx={{ width: { xs: 1, sm: 1 / 3 } }}>
                        <NumberInput
                          source="LastStopLossMinValue"
                          step={0.01}
                          disabled
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <>
          <TextInput
            source="LocalUserID"
            disabled
            className={classes.isHidden}
            defaultValue={PersonId}
          />
        </>
      </FormTab>
    </TabbedForm>
  );
};

export const RoleForm = (props: any) => {
  let isCreate = props.dialogType === "Create";
  const record = useRecordContext(props);
  let name = "Role";
  let entityType = "Role";
  let resource = pluralize(entityType).toLowerCase();
  let roleTypeId = 0;
  let recordName = "";
  if (record !== undefined) {
    recordName = record.name;
    roleTypeId = Number(record.id);
  }
  const classes = useStyles();

  return (
    <TabbedForm
      toolbar={
        <PostEditToolbar
          isTabbed={true}
          title={name}
          name={recordName}
          resource={resource}
        />
      }
    >
      <FormTab label={name}>
        <Box sx={{ width: "100%", maxWidth: "1200px" }}>
          <Box
            sx={{ width: 1, paddingBottom: { xs: 0, md: "10px" } }}
            display={{ xs: "block", md: "flex" }}
          >
            <Box
              sx={{ width: { xs: 1, md: 1 / 2 } }}
              mr={{ xs: 0, md: "0.5em" }}
              mb={{ xs: 0, sm: 0 }}
            >
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1, md: 3 / 5 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    autoFocus
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    source="name"
                  />
                </Box>
              </Box>
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    multiline
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    source="description"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </FormTab>
    </TabbedForm>
  );
};

export const LocalUserForm = (props: any) => {
  let isCreate = props.dialogType === "Create";
  const record = useRecordContext(props);
  let name = "LocalUser";
  let entityType = "LocalUser";
  let resource = pluralize(entityType).toLowerCase();
  let localUserId = 0;
  let recordName = "";
  if (record !== undefined) {
    recordName = record.name;
    localUserId = Number(record.id);
  }
  const classes = useStyles();

  return (
    <TabbedForm
      toolbar={
        <PostEditToolbar
          isTabbed={true}
          title={name}
          name={recordName}
          resource={resource}
        />
      }
    >
      <FormTab label={"User"}>
        <Box sx={{ width: "100%", maxWidth: "1200px" }}>
          <Box
            sx={{ width: 1, paddingBottom: { xs: 0, md: "10px" } }}
            display={{ xs: "block", md: "flex" }}
          >
            <Box
              sx={{ width: { xs: 1, md: 1 / 2 } }}
              mr={{ xs: 0, md: "0.5em" }}
              mb={{ xs: 0, sm: 0 }}
            >
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1, md: 3 / 5 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    autoFocus
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    source="DisplayName"
                  />
                  <BooleanInput source="IsAdmin" />
                </Box>
              </Box>
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    multiline
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    source="UserName"
                  />
                </Box>
              </Box>
              <hr />
              <Box
                sx={{ width: 1, paddingTop: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    label="Please enter a description for this token"
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    source="TokenDescription"
                  />
                </Box>
              </Box>
              <Box
                sx={{ width: 1, paddingTop: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    multiline
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    source="StreamingQuotesToken"
                  />
                </Box>
              </Box>
              <hr />
              <Box
                sx={{ width: 1, paddingTop: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    label="Trading Account ID"
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    source="MainAccountId"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </FormTab>
      {!isCreate && (
        <FormTab label="Roles">
          <MyReferenceManyField
            label="Roles"
            reference="localuserroles"
            target="LocalUserID"
          >
            <EditableDatagrid
              mutationMode="optimistic"
              title="User Role"
              actions={
                <CustomAction
                  title="User Role"
                  displayField="name"
                  label={<InlineCreateButton />}
                />
              }
              createForm={
                <LocalUserRoleFormEditableGrid localUserId={localUserId} />
              }
              editForm={
                <LocalUserRoleFormEditableGrid localUserId={localUserId} />
              }
            >
              <ReferenceField label="Role" source="roleId" reference="roles">
                <TextField source="name" />
              </ReferenceField>
            </EditableDatagrid>
          </MyReferenceManyField>
        </FormTab>
      )}
      {!isCreate && (
        <FormTab label="Trading Platforms">
          <MyReferenceManyField
            label="Trading Platforms"
            reference="localusertradingplatforms"
            target="LocalUserID"
          >
            <EditableDatagrid
              mutationMode="optimistic"
              title="User Role"
              actions={
                <CustomAction
                  title="User Trading Platform"
                  displayField="name"
                  label={<InlineCreateButton />}
                />
              }
              createForm={
                <LocalUserTradingPlatformFormEditableGrid
                  localUserId={localUserId}
                />
              }
              editForm={
                <LocalUserTradingPlatformFormEditableGrid
                  localUserId={localUserId}
                />
              }
            >
              <ReferenceField
                label="Trading Platform"
                source="tradingPlatformId"
                reference="tradingplatforms"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="AccessKey" />
            </EditableDatagrid>
          </MyReferenceManyField>
        </FormTab>
      )}
    </TabbedForm>
  );
};

export const PermissionForm = (props: any) => {
  let isCreate = props.dialogType === "Create";
  const record = useRecordContext(props);
  let name = "Role";
  let entityType = "RoleType";
  let resource = pluralize(entityType).toLowerCase();
  let roleTypeId = 0;
  let recordName = "";
  if (record !== undefined) {
    recordName = record.name;
    roleTypeId = Number(record.id);
  }
  const classes = useStyles();

  return (
    <TabbedForm
      toolbar={
        <PostEditToolbar
          isTabbed={true}
          title={name}
          name={recordName}
          resource={resource}
        />
      }
    >
      <FormTab label={name}>
        <Box sx={{ width: "100%", maxWidth: "1200px" }}>
          <Box
            sx={{ width: 1, paddingBottom: { xs: 0, md: "10px" } }}
            display={{ xs: "block", md: "flex" }}
          >
            <Box
              sx={{ width: { xs: 1, md: 1 / 2 } }}
              mr={{ xs: 0, md: "0.5em" }}
              mb={{ xs: 0, sm: 0 }}
            >
              <Box
                sx={{ width: 1, paddingBottom: "10px" }}
                display={{ xs: "block", sm: "flex" }}
              >
                <Box
                  sx={{ width: { xs: 1 } }}
                  mr={{ xs: 0, sm: "0.5em" }}
                  mb={{ xs: "0.5em", sm: 0 }}
                >
                  <TextInput
                    multiline
                    fullWidth
                    helperText={false}
                    validate={requiredValidate}
                    source="description"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </FormTab>

      {!isCreate && (
        <FormTab label="Permissions">
          <MyReferenceManyField
            label="Permissions"
            reference="modelpermissions"
            target="roleTypeId"
          >
            <EditableDatagrid
              mutationMode="optimistic"
              createForm={
                <RolePermissionsFormEditableGrid roleTypeId={`${record.id}`} />
              }
              editForm={
                <RolePermissionsFormEditableGrid roleTypeId={`${record.id}`} />
              }
            >
              <ReferenceField
                label="Models"
                source="systemModelId"
                reference="systemmodels"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Permissions"
                source="permissionTypeId"
                reference="permissiontypes"
              >
                <TextField source="name" />
              </ReferenceField>
            </EditableDatagrid>
          </MyReferenceManyField>
        </FormTab>
      )}
    </TabbedForm>
  );
};

export const ContractItemTaskForm = (props: any) => {
  const record = useRecordContext(props);
  let recordName = "";
  if (record !== undefined) {
    recordName = record.name;
  }
  let name = "Contract Item Task";
  let parentId = 0;
  if (props.parentId !== undefined) {
    parentId = props.parentId;
  }
  const classes = useStyles();
  return (
    <SimpleForm
      sx={{ maxWidth: 750 }}
      toolbar={<PostEditToolbar title={name} name={recordName} />}
    >
      <h2>Contract Item Task</h2>
      <TextInput
        fullWidth
        helperText={false}
        validate={requiredValidate}
        source="name"
      />
      <TextInput multiline fullWidth source="description" helperText={false} />
      <TextInput source="taskDocUri" />
      <MyNumberInput
        source="relativeSequence"
        defaultValue={"0"}
        className={classes.isHidden}
      />
      <TextInput
        source="contractItemId"
        disabled
        className={classes.isHidden}
        defaultValue={parentId}
        value={parentId}
      />
    </SimpleForm>
  );
};

export const GenerateWorkOrderTasksForm = (props: any) => {
  const record = props.currentRecord;
  let salesOrderId = 0;
  let contractId = 0;
  let workOrderNumber = "";
  if (record.customerId !== undefined) {
    workOrderNumber = record.workOrderNumber;
  }
  let name = "Generate Work Order Tasks";
  const classes = useStyles();
  const notify = useNotify();
  const redirectTo = useRedirect();

  const [update, { isLoading, error }] = useUpdate();

  const CustomToolbar = (props: any) => {
    return (
      <Toolbar {...props}>
        <>
          <SaveButton
            label={"Generate Work Order Tasks"}
            icon={<AddBoxIcon />}
            type="button"
          />
        </>
      </Toolbar>
    );
  };

  let hasErrors = false;
  const refresh = useRefresh();
  const save = (data: any) => {
    console.log(data);

    //event.preventDefault();
    //use newData or data
    if (!hasErrors) {
      update(
        "generateworkordertasks",
        {
          id: data.workOrderId,
          data: {
            workOrderId: data.workOrderId,
            entityType: data.entityType,
            entityId: data.entityId,
          },
        },
        {
          onSuccess: (data) => {
            notify("Work Order Tasks Generated.", {
              type: "success",
              autoHideDuration: 5000,
            });

            let element: HTMLElement = document.getElementsByClassName(
              "RaFormDialogTitle-closeButton"
            )[0] as HTMLElement;
            element.click();
            refresh();

            redirectTo("/workorders/" + record.id + "/1");
          },
          onError: (error: any) => {
            let i = 0;
            if (!hasErrors) {
              notify(error.body.message, {
                type: "warning",
              });
            }
          },
        }
      );
    }
  };
  const saving = false;
  const mutationMode = "pessimistic";

  return (
    <SaveContextProvider value={{ save, saving, mutationMode }}>
      <SimpleForm toolbar={<CustomToolbar mode="onBlur" />}>
        <h4>Pick from one of the following to generate tasks...</h4>
        <RadioButtonGroupInput
          source="entityType"
          label=""
          choices={[
            { id: "SalesOrderItem", name: "Sales Order Item" },
            { id: "ContractItem", name: "Contract Item" },
            { id: "MasterItem", name: "Master Item" },
          ]}
        />
        <ShowHideField
          watchField="entityType"
          watchValue="SalesOrderItem"
          watchComparison="==="
        >
          <Box
            sx={{ width: 1, paddingBottom: "10px" }}
            display={{ xs: "block", md: "flex" }}
          >
            <Box
              sx={{ width: { xs: 1, md: 1 / 2 } }}
              mr={{ xs: 0, sm: "0.5em" }}
              mb={{ xs: "0.5em", sm: 0 }}
            >
              <MyReferenceInput
                {...(!hasRole(LoginRoleTypes.SUPER)
                  ? {
                      filter: {
                        internalBusinessEntityId: InternalBusinessEntityId,
                        customerId: record.customerId,
                        isEstimate: false,
                      },
                    }
                  : { q: "parent" })}
                source="salesOrderId"
                reference="salesorders"
              >
                <MyAutoCompleteInput
                  label="Sales Order"
                  optionText="salesOrderNumber"
                  validate={requiredValidate}
                />
              </MyReferenceInput>
            </Box>
            <Box
              sx={{ width: { xs: 1, md: 1 / 2 } }}
              mr={{ xs: 0, sm: "0.5em" }}
              mb={{ xs: "0.5em", sm: 0 }}
            >
              <DependentReferenceInput
                label="Sales Order Items"
                {...(!hasRole(LoginRoleTypes.SUPER)
                  ? {
                      filter: {
                        salesOrderId: salesOrderId,
                      },
                    }
                  : {
                      filter: { salesOrderId: salesOrderId },
                    })}
                source="entityId"
                reference="salesorderitems"
                referenceSourceField="salesOrderId"
                dependencyFilterField="salesOrderId"
              >
                <MyAutoCompleteInput
                  label="Sales Order Items"
                  validate={requiredValidate}
                />
              </DependentReferenceInput>
            </Box>
          </Box>
        </ShowHideField>

        <ShowHideField
          watchField="entityType"
          watchValue="ContractItem"
          watchComparison="==="
        >
          <Box
            sx={{ width: 1, paddingBottom: "10px" }}
            display={{ xs: "block", md: "flex" }}
          >
            <Box
              sx={{ width: { xs: 1, md: 1 / 2 } }}
              mr={{ xs: 0, sm: "0.5em" }}
              mb={{ xs: "0.5em", sm: 0 }}
            >
              <MyReferenceInput
                fullWidth
                {...(!hasRole(LoginRoleTypes.SUPER)
                  ? {
                      filter: {
                        internalBusinessEntityId: InternalBusinessEntityId,
                      },
                    }
                  : {})}
                source="contractId"
                reference="contracts"
              >
                <MyAutoCompleteInput
                  label="Contracts"
                  validate={requiredValidate}
                />
              </MyReferenceInput>
            </Box>
            <Box
              sx={{ width: { xs: 1, md: 1 / 2 } }}
              mr={{ xs: 0, sm: "0.5em" }}
              mb={{ xs: "0.5em", sm: 0 }}
            >
              <DependentReferenceInput
                label="Contract Items"
                {...(!hasRole(LoginRoleTypes.SUPER)
                  ? {
                      filter: {
                        contractId: contractId,
                      },
                    }
                  : {
                      filter: { contractId: contractId },
                    })}
                source="entityId"
                reference="contractitems"
                referenceSourceField="contractId"
                dependencyFilterField="contractId"
              >
                <MyAutoCompleteInput
                  label="Contract Items"
                  validate={requiredValidate}
                />
              </DependentReferenceInput>
            </Box>
          </Box>
        </ShowHideField>

        <ShowHideField
          watchField="entityType"
          watchValue="MasterItem"
          watchComparison="==="
        >
          <Box
            sx={{ width: 1, paddingBottom: "10px" }}
            display={{ xs: "block", md: "flex" }}
          >
            <Box
              sx={{ width: { xs: 1, md: 1 / 2 } }}
              mr={{ xs: 0, sm: "0.5em" }}
              mb={{ xs: "0.5em", sm: 0 }}
            >
              <MyReferenceInput
                {...(!hasRole(LoginRoleTypes.SUPER)
                  ? {
                      filter: {
                        internalBusinessEntityId: InternalBusinessEntityId,
                      },
                    }
                  : {})}
                source="entityId"
                reference="ibemasteritems"
                fullWidth
              >
                <MyAutoCompleteInput
                  label="Master Items"
                  validate={requiredValidate}
                />
              </MyReferenceInput>
            </Box>
          </Box>
        </ShowHideField>

        <TextInput
          source="workOrderId"
          className={classes.isHidden}
          disabled
          defaultValue={record.id}
        />
      </SimpleForm>
    </SaveContextProvider>
  );
};

export const PeopleForm = (props: any) => {
  let name = "Employee";
  let entityType = "People";
  let resource = pluralize(entityType).toLowerCase();
  let isCreate = props.dialogType === "Create";
  const record = useRecordContext(props);
  let recordName = "";
  if (record !== undefined) {
    recordName = record.name;
  }

  const classes = useStyles();
  return (
    <TabbedForm
      toolbar={
        <PostEditToolbar
          isTabbed={true}
          title={name}
          name={recordName}
          resource={resource}
        />
      }
    >
      <FormTab label="Employee">
        <h2>Employee</h2>
        <TextField source="id" />
        <TextInput
          autoFocus
          fullWidth
          helperText={false}
          validate={requiredValidate}
          source="name"
        />
        <DisabledField fieldType="BooleanInput" />
        <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="phone" fullWidth validate={requiredValidate} />
        </Box>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="email" fullWidth validate={requiredValidate} />
          </Box>
          <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="userName" fullWidth required={false} />
          </Box>
        </Box>
        <TextInput fullWidth source="notes" helperText={false} />
        <TextInput
          source="entityType"
          className={classes.isHidden}
          disabled
          defaultValue={"InternalBusinessEntity"}
        />
        <EntityIdReferenceInput />
      </FormTab>

      {!isCreate && (
        <FormTab label="Login Roles">
          <MyReferenceManyField
            label="Roles"
            reference="personroles"
            target="personId"
          >
            <EditableDatagrid
              mutationMode="optimistic"
              title="End User Role"
              actions={
                <CustomAction
                  title="End User Role"
                  displayField="name"
                  label={<InlineCreateButton />}
                />
              }
              createForm={
                <LocalUserRoleFormEditableGrid personId={`${record.id}`} />
              }
              editForm={
                <LocalUserRoleFormEditableGrid personId={`${record.id}`} />
              }
            >
              <ReferenceField
                label="Role"
                source="roleTypeId"
                reference="roletypes"
              >
                <TextField source="name" />
              </ReferenceField>
            </EditableDatagrid>
          </MyReferenceManyField>
        </FormTab>
      )}
    </TabbedForm>
  );
};

export const UserProfileForm = (props: any) => {
  let name = "Employee";
  let entityType = "People";
  let resource = pluralize(entityType).toLowerCase();
  let isCreate = props.dialogType === "Create";
  const record = useRecordContext(props);
  let recordName = "";
  if (record !== undefined) {
    recordName = record.name;
  }

  let currentUserRoles = getUserRoles();

  const classes = useStyles();
  return (
    <SimpleForm
      sx={{ maxWidth: 750 }}
      validate={props.validate}
      toolbar={
        <PostEditToolbar
          allowDelete={false}
          allowSave={false}
          allowUpdate={false}
          title={"User Profile"}
          name={recordName}
        />
      }
    >
      <h2>User Profile</h2>
      <TextInput
        autoFocus
        fullWidth
        helperText={false}
        validate={requiredValidate}
        source="name"
        disabled={true}
        color="primary"
      />
      <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
        <TextInput
          source="phone"
          fullWidth
          validate={requiredValidate}
          disabled={true}
        />
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="email"
            fullWidth
            validate={requiredValidate}
            disabled={true}
          />
        </Box>
        <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="userName"
            fullWidth
            required={false}
            disabled={true}
          />
        </Box>
      </Box>
      <TextInput fullWidth source="notes" helperText={false} disabled={true} />
      <hr />
      <h3>Current Roles</h3>
      <p>{currentUserRoles}</p>

      <TextInput
        source="entityType"
        className={classes.isHidden}
        disabled
        defaultValue={"InternalBusinessEntity"}
      />
      <EntityIdReferenceInput disabled={true} />
    </SimpleForm>
  );
};

export const StateForm = () => (
  <SimpleForm>
    <h2>State</h2>
    <TextInput source="name" />
    <TextInput source="code" />
    <MyReferenceInput
      source="countryId"
      reference="countries"
      validate={requiredValidate}
    >
      <SelectInput source="id" />
    </MyReferenceInput>
  </SimpleForm>
);

// APP SETTINGS FORM
export const AppSettingsForm = () => {
  const record = useRecordContext();
  let isChecked = false;
  let isSuper = hasRole(LoginRoleTypes.SUPER);

  if (record !== undefined) {
    if (record.ValueType === "Boolean" && record.value === "true") {
      isChecked = true;
    }
  }
  let truefalse = null;
  const { register, getValues } = useForm();
  const classes = useStyles();

  if (record === undefined) {
    return (
      <SimpleForm>
        {isSuper && (
          <MyReferenceInput source="LocalUserID" reference="localusers">
            <MyAutoCompleteInput label="User" optionText="DisplayName" />
          </MyReferenceInput>
        )}
        <TextInput source="Key" />
        <TextInput source="ValueType" />
        <TextInput source="Value" />
        <TextInput source="Description" />
      </SimpleForm>
    );
  } else if (record.ValueType === "Boolean") {
    return (
      <SimpleForm>
        {isSuper && (
          <MyReferenceInput source="LocalUserID" reference="localusers">
            <MyAutoCompleteInput
              disabled
              label="User"
              optionText="DisplayName"
            />
          </MyReferenceInput>
        )}
        <TextInput source="Key" disabled />
        <TextInput source="ValueType" disabled />
        <TextInput source="Value" label={record.Description} />
        <TextInput source="Description" disabled className={classes.isHidden} />
      </SimpleForm>
    );
  } else if (record.ValueType === "String") {
    return (
      <SimpleForm>
        {isSuper && (
          <MyReferenceInput source="LocalUserID" reference="localusers">
            <MyAutoCompleteInput
              disabled
              label="User"
              optionText="DisplayName"
            />
          </MyReferenceInput>
        )}
        <TextInput source="Key" disabled />
        <TextInput source="ValueType" disabled />
        <TextInput source="Value" label={record.Description} />
        <TextInput source="Description" disabled className={classes.isHidden} />
      </SimpleForm>
    );
  }
};
